import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Container,
  Row,
  Input,
  Label,
  Col,
  CardTitle,
  CardText,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import ReactApexChart from "react-apexcharts"

import { withTranslation } from "react-i18next"
import { __getApiData, __putApiData } from "constants/apis"
const MyAccount = props => {
  const [state, setState] = useState({
    isShowModel: false,
    type: null,
  })
  const {
    isShowModel,
    pan,
    type,
    customer_pan_no,
    permanent_address,
    p_state,
    p_city,
    p_pin,
    income_details_occupation,
    income_details_monthly_income,
    income_details_professional_details,
    name,
    care_of_name,
    gender,
    dob,
    marital_status,
    email,
  } = state

  const updateState = data => setState(state => ({ ...state, ...data }))

  const series = [67]
  const options = {
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 135,
        dataLabels: {
          name: {
            fontSize: "13px",
            color: void 0,
            offsetY: 60,
          },
          value: {
            offsetY: 22,
            fontSize: "16px",
            color: void 0,
            formatter: function (e) {
              return e + "%"
            },
          },
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        shadeIntensity: 0.15,
        inverseColors: !1,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 65, 91],
      },
    },
    stroke: {
      dashArray: 4,
    },
    labels: [""],
  }

  const __handleGetUserData = () => {
    __getApiData(`/g_user`)
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          updateState({ ...res.data })
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  useEffect(() => {
    __handleGetUserData()
  }, [])
  const __handleUpdateUserData = data => {
    __putApiData(`/u_user`, data)
      .then(res => {
        console.log(res)

        if (res.response.response_code === "200") {
          // updateState({ ...res.data })
          updateState({ isShowModel: false, type: null })
          return window.alert("Success")
        }
        window.alert("Failed to update")
      })
      .catch(error => {
        console.error(error)
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={4} md={"6"}>
              <Card className="mt-5 p-5 rounded-5">
                <CardBody className=" d-flex justify-content-center align-items-center flex-column">
                  <div className="avatar-md profile-user-wid mb-4">
                    <img
                      className="rounded-circle avatar-lg"
                      alt="200x200"
                      src="/static/media/6.b23f3e1416fbe3f9b615.png"
                      data-holder-rendered="true"
                    />
                  </div>

                  <CardTitle className="pt-3">
                    {localStorage.getItem("name")}
                  </CardTitle>
                  <CardText className="">
                    {localStorage.getItem("email")}
                  </CardText>
                  <CardText className="">
                    {localStorage.getItem("mobile_no")}
                  </CardText>
                </CardBody>
              </Card>
              <Card className="mt-2 p-2 rounded-5">
                <CardBody className="  ">
                  <ReactApexChart
                    options={options}
                    series={series}
                    type="radialBar"
                    height="200"
                    className="apex-charts "
                  />
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Eligendi quidem sunt ipsum, debitis quo nulla. Voluptatum
                    libero totam perspiciatis vitae sit illum neque quam
                    aperiam!
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col lg={8} md={"6"}>
              <Card className=" p-2 rounded-5 mt-lg-5 mt-sm-2">
                <CardBody className="gap-3  d-flex flex-column align-items-start">
                  <div>
                    <div
                      className="bg-light p-3 rounded-4 d-flex align-items-center mb-3"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        updateState({ isShowModel: true, type: "pan" })
                      }
                    >
                      <h4 className="m-1 p-0 pe-5">PAN Verification</h4>
                      <i className="bx bx-chevrons-right fs-2"></i>
                    </div>
                    <div
                      className="bg-light p-3 rounded-4 d-flex align-items-center mb-3"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        updateState({ isShowModel: true, type: "basic" })
                      }
                    >
                      <h4 className="m-1 p-0 pe-5">BASIC Information</h4>
                      <i className="bx bx-chevrons-right fs-2"></i>
                    </div>
                    <div
                      style={{ cursor: "pointer" }}
                      className="bg-light p-3 rounded-4 d-flex align-items-center mb-3"
                      onClick={() =>
                        updateState({ isShowModel: true, type: "address" })
                      }
                    >
                      <h4 className="m-1 p-0 pe-5">RESIDENCE Address</h4>
                      <i className="bx bx-chevrons-right fs-2"></i>
                    </div>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        updateState({ isShowModel: true, type: "income" })
                      }
                      className="bg-light p-3 rounded-4 d-flex align-items-center mb-3"
                    >
                      <h4 className="m-1 p-0 pe-5">INCOME Details</h4>
                      <i className="bx bx-chevrons-right fs-2"></i>
                    </div>
                    <div
                      style={{ cursor: "pointer" }}
                      className="bg-light p-3 rounded-4 d-flex align-items-center"
                    >
                      <h4 className="m-1 p-0 pe-5 "> SELFIE Upload</h4>
                      <i className="bx bx-chevrons-right fs-2"></i>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {addPlan()}
    </React.Fragment>
  )

  function addPlan(params) {
    return (
      <Modal isOpen={isShowModel} centered size="lg">
        <ModalHeader
          toggle={() => updateState({ isShowModel: false })}
        ></ModalHeader>
        <ModalBody className="rounded-0 p-5">
          {type == "pan" && panVerification()}
          {type == "income" && inComeDetials()}
          {type == "basic" && basicDetials()}
          {type == "address" && addressDetails()}
        </ModalBody>
      </Modal>
    )
  }
  function panVerification(params) {
    return (
      <>
        <Row>
          <Col md={12} className="">
            <div className="mb-3 col-md-12">
              <Label className="form-label"> PAN No.</Label>
              <Input
                name="name"
                type="text"
                placeholder=""
                className=" border-0 bg-light rounded-0 p-3"
                value={customer_pan_no}
                onChange={e => {
                  updateState({ customer_pan_no: e.target.value })
                }}
              />
            </div>
            <button
              className="btn btn-success float-end"
              onClick={() => {
                if (!customer_pan_no) {
                  return window.alert("please enter your pan card number")
                }
                __handleUpdateUserData({
                  customer_pan_no,
                })
              }}
            >
              Verify
            </button>
          </Col>
        </Row>
      </>
    )
  }
  function addressDetails(params) {
    return (
      <>
        <Row>
          <Col md={12} className="">
            <div className="mb-3 col-md-12">
              <Label className="form-label">Parmanent Address</Label>
              <Input
                name="name"
                type="textarea"
                placeholder=""
                className=" border-0 bg-light rounded-0 p-3"
                value={permanent_address}
                onChange={e => {
                  updateState({ permanent_address: e.target.value })
                }}
              />
            </div>
          </Col>
          <Col md={"4"} className="">
            <Label className="form-label"> State</Label>
            <div className="">
              <select
                className="form-select border-0 bg-light rounded-0 p-3"
                name="p_state"
                value={p_state}
                onChange={e => {
                  updateState({ [e.target.name]: e.target.value })
                }}
              >
                <option value="">choose</option>
                <option value="MP">MP</option>
              </select>
            </div>
          </Col>
          <Col md={"4"} className="">
            <Label className="form-label"> City</Label>
            <div className="">
              <Input
                type="text"
                placeholder="Type here..."
                className=" border-0 bg-light rounded-0 p-3"
                name="p_city"
                value={p_city}
                onChange={e => {
                  updateState({ [e.target.name]: e.target.value })
                }}
              />
            </div>
          </Col>
          <Col md={"4"} className="">
            <Label className="form-label"> Pin Code</Label>
            <div className="">
              <Input
                type="number"
                placeholder="Type here..."
                className=" border-0 bg-light rounded-0 p-3"
                name="p_pin"
                value={p_pin}
                onChange={e => {
                  updateState({ [e.target.name]: e.target.value })
                }}
              />
            </div>
          </Col>
        </Row>
        <button
          className="btn btn-success float-end mt-3"
          onClick={() => {
            if (!permanent_address) {
              return window.alert("Parmanent Address")
            }
            if (!p_state) {
              return window.alert("please enter your state")
            }
            if (!p_city) {
              return window.alert("please enter your city")
            }
            if (!p_pin) {
              return window.alert("please enter your pin code")
            }
            __handleUpdateUserData({
              permanent_address,
              p_pin,
              p_state,
              p_city,
            })
          }}
        >
          Update
        </button>
      </>
    )
  }
  function inComeDetials(params) {
    return (
      <>
        <Row>
          <Col md={"6"} className="">
            <Label className="form-label"> Occupation</Label>
            <div className="">
              <select
                className="form-select border-0 bg-light rounded-0 p-3"
                name="income_details_occupation"
                value={income_details_occupation}
                onChange={e => {
                  updateState({ [e.target.name]: e.target.value })
                }}
              >
                <option value={""}>choose</option>
                <option value={"Salaried"}>Salaried</option>
                <option value={"Self-Employed"}>Self-Employed</option>
              </select>
            </div>
          </Col>
          <Col md={6} className="">
            <div className="mb-3 col-md-12">
              <Label className="form-label"> Monthly Total Family Income</Label>
              <Input
                name="name"
                type="number"
                placeholder=""
                className=" border-0 bg-light rounded-0 p-3"
                value={income_details_monthly_income}
                onChange={e => {
                  updateState({ income_details_monthly_income: e.target.value })
                }}
              />
            </div>
          </Col>
          <Col md={"12"} className="">
            <Label className="form-label">Professional Details</Label>
            <div className="">
              <Input
                type="textarea"
                placeholder="Type here..."
                className=" border-0 bg-light rounded-0 p-3"
                name="income_details_professional_details"
                value={income_details_professional_details}
                onChange={e => {
                  updateState({ [e.target.name]: e.target.value })
                }}
              />
            </div>
            <button
              className="btn btn-success float-end mt-3"
              onClick={() => {
                if (!income_details_occupation) {
                  return window.alert("please Select Occupation")
                }

                if (!income_details_monthly_income) {
                  return window.alert(
                    "please enter Monthly Total Family Income"
                  )
                }
                if (!income_details_professional_details) {
                  return window.alert("please enter Professional Details")
                }
                __handleUpdateUserData({
                  income_details_professional_details,
                  income_details_occupation,
                  income_details_monthly_income,
                })
              }}
            >
              Update
            </button>
          </Col>
        </Row>
      </>
    )
  }
  function basicDetials(params) {
    return (
      <>
        <Row>
          <Col md={"6"} className=" mb-3">
            <Label className="form-label">Name</Label>
            <div className="">
              <Input
                type="text"
                placeholder="Type here..."
                className=" border-0 bg-light rounded-0 p-3"
                name="name"
                value={name}
                onChange={e => {
                  updateState({ [e.target.name]: e.target.value })
                }}
              />
            </div>
          </Col>
          <Col md={"6"} className=" mb-3">
            <Label className="form-label">Care of</Label>
            <div className="">
              <Input
                type="text"
                placeholder="Type here..."
                className=" border-0 bg-light rounded-0 p-3"
                name="care_of_name"
                value={care_of_name}
                onChange={e => {
                  updateState({ [e.target.name]: e.target.value })
                }}
              />
            </div>
          </Col>
          <Col md={"6"} className="">
            <Label className="form-label"> Gender</Label>
            <div className="">
              <select
                className="form-select border-0 bg-light rounded-0 p-3"
                name="gender"
                value={gender}
                onChange={e => {
                  updateState({ [e.target.name]: e.target.value })
                }}
              >
                <option value={""}>choose</option>
                <option value={"Male"}>Male</option>
                <option value={"Female"}>Female</option>
              </select>
            </div>
          </Col>
          <Col md={6} className="">
            <div className="mb-3 col-md-12">
              <Label className="form-label"> DOB</Label>
              <Input
                name="name"
                type="date"
                placeholder=""
                className=" border-0 bg-light rounded-0 p-3"
                value={dob}
                onChange={e => {
                  updateState({ dob: e.target.value })
                }}
              />
            </div>
          </Col>

          <Col md={"6"} className="">
            <Label className="form-label"> Marital Status </Label>
            <div className="">
              <select
                className="form-select border-0 bg-light rounded-0 p-3"
                name="marital_status"
                value={marital_status}
                onChange={e => {
                  updateState({ [e.target.name]: e.target.value })
                }}
              >
                <option value="">choose</option>
                <option value="Married">Married</option>
                <option value="UnMarid">Unmarried</option>
              </select>
            </div>
          </Col>
          <Col md={6} className="">
            <div className="mb-3 col-md-12">
              <Label className="form-label"> Email ID</Label>
              <Input
                name="name"
                type="text"
                placeholder=""
                className=" border-0 bg-light rounded-0 p-3"
                value={email}
                onChange={e => {
                  updateState({ email: e.target.value })
                }}
              />
            </div>
            <button
              className="btn btn-success float-end"
              onClick={() => {
                if (!name) {
                  return window.alert("please enter name")
                }
                if (!care_of_name) {
                  return window.alert("please enter Care of")
                }
                if (!gender) {
                  return window.alert("please Select gender")
                }
                if (!dob) {
                  return window.alert("please pick dob")
                }
                if (!marital_status) {
                  return window.alert("please select marital status")
                }
                __handleUpdateUserData({})
              }}
            >
              {" "}
              Update
            </button>
          </Col>
        </Row>
      </>
    )
  }
}

export default withTranslation()(MyAccount)
