import React from "react"
import { Navigate } from "react-router-dom"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import ForgetPassword from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import MyAccount from "pages/MyAccount"
import LoanRequest from "pages/LoanRequest"
import ActiveLoan from "pages/ActiveLoan"
import LoanInstallment from "pages/LoanInstallment"

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/my-account", component: <MyAccount /> },
  { path: "/loan-request", component: <LoanRequest /> },
  { path: "/active-loan", component: <ActiveLoan /> },
  { path: "/loan-installment", component: <LoanInstallment /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  {
    path: "*",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
]

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPassword /> },
]

export { authProtectedRoutes, publicRoutes }
