import React, { useEffect, useMemo, useState } from "react"
import {
  Card,
  CardBody,
  Container,
  Row,
  Input,
  Label,
  Col,
  CardTitle,
  CardText,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"

import { withTranslation } from "react-i18next"
import { __getApiData, __postApiData, __putApiData } from "constants/apis"
import { __formatDate } from "constants/constantfun"
import TableContainer from "components/Common/TableContainer"
const LoanRequest = props => {
  const [state, setState] = useState({
    isShowModel: false,
    type: null,
    list: [],
    isAdd: false,
    isLoading: false,
  })
  const { isShowModel, list, isLoading, isAdd, duration, amount } = state

  const updateState = data => setState(state => ({ ...state, ...data }))

  const __handleGetData = () => {
    updateState({ isLoading: true })
    __getApiData(`/g_my_loan_request`)
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          updateState({ list: res.data, isLoading: false })
        }
        updateState({ isLoading: false })
      })
      .catch(error => {
        console.error(error)
        updateState({ isLoading: false })
      })
  }
  const __handlePostRequestData = () => {
    if (!duration || !amount) {
      return window.alert("loan amound and loan duration is required")
    }
    __postApiData(`/c_my_loan_request`, {
      duration,
      amount,
    })
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          updateState({ isAdd: false })
          __handleGetData()
        }
        return window.alert(res.response.response_message)
      })
      .catch(error => {
        console.error(error)
        return window.alert("Failed Error")
      })
  }
  const __handleUpdateRequestData = (status, id) => {
    __putApiData(`/u_my_loan_request/${id}`, {
      status,
    })
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          __handleGetData()
        }
        return window.alert(res.response.response_message)
      })
      .catch(error => {
        console.error(error)
        return window.alert("Failed Error")
      })
  }

  useEffect(() => {
    __handleGetData()
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: <div className="text-center">#</div>,
        filterable: false,
        disableFilters: true,
        accessor: "sr_no",
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value}</div>
        },
      },

      {
        Header: <div className="text-center"> Amount</div>,
        accessor: "amount",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value}</div>
        },
      },
      {
        Header: <div className="text-center"> Duration</div>,
        accessor: "duration",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <div className="text-center">₹{cellProps.value || "0"}</div>
        },
      },
      {
        Header: <div className="text-center"> Status</div>,
        accessor: "status",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return (
            <div className="text-center">
              <ul className="list-unstyled hstack gap-1 mb-0 justify-content-center">
                {cellProps.value == "Pending" && (
                  <li>
                    <span className="btn btn-sm btn-warning font-size-10 p-0 px-2">
                      {cellProps.value}
                    </span>
                  </li>
                )}
                {cellProps.value == "Cancel" && (
                  <li>
                    <span className="btn btn-sm btn-danger font-size-10 p-0 px-2">
                      {cellProps.value}
                    </span>
                  </li>
                )}
                {cellProps.value == "Rejected" && (
                  <li>
                    <span className="btn btn-sm btn-danger font-size-10 p-0 px-2">
                      {cellProps.value}
                    </span>
                  </li>
                )}
                {cellProps.value == "Accepted" && (
                  <li>
                    <span className="btn btn-sm btn-success fs-6 font-size-10 p-0 px-2">
                      {cellProps.value}
                    </span>
                  </li>
                )}
              </ul>
            </div>
          )
        },
      },

      {
        Header: <div className="text-center">Date</div>,
        accessor: "createdAt",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return (
            <div className="text-center">
              {__formatDate(cellProps.value) || "-"}
            </div>
          )
        },
      },
      {
        Header: <div className="text-center">Action</div>,
        accessor: "_id",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          const data = cellProps.data.find(elem => elem._id === cellProps.value)

          return (
            <ul className="list-unstyled hstack gap-1 mb-0 justify-content-center">
              <li>
                {data.status == "Pending" && (
                  <span
                    className="btn btn-sm btn-soft-danger"
                    onClick={() => {
                      __handleUpdateRequestData("Cancel", cellProps.value)
                    }}
                  >
                    Cancel Request
                  </span>
                )}
              </li>
            </ul>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card className=" rounded-4  py-3">
            <CardBody>
              <TableContainer
                columns={columns}
                data={list}
                customPageSize={30}
                classNameHeader="dark"
                isGlobalFilter
                isAddUserList={"New Request"}
                handleUserClick={() => {
                  updateState({ isAdd: true })
                }}
              />
              {list?.length == 0 && !isLoading && (
                <div className="p-5">
                  <div className="text-center ">
                    <i
                      className="bx bx-search"
                      style={{ fontSize: "30px" }}
                    ></i>
                  </div>
                  <div className="text-center mt-3">No request Found</div>
                </div>
              )}
              {isLoading && (
                <div className="p-5">
                  <div className="text-center ">
                    <i
                      className="bx bx-loader bx-spin"
                      style={{ fontSize: "30px" }}
                    ></i>
                  </div>
                </div>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
      {addPlan()}
    </React.Fragment>
  )

  function addPlan(params) {
    return (
      <Modal isOpen={isAdd} centered size="lg">
        <ModalHeader toggle={() => updateState({ isAdd: false })}></ModalHeader>
        <ModalBody className="rounded-0 p-5">
          <Row>
            <Col md={6} className="">
              <div className="mb-3 col-md-12">
                <Label className="form-label"> Loan Amount</Label>
                <Input
                  name="name"
                  type="number"
                  placeholder=""
                  className=" border-0 bg-light rounded-0 p-3"
                  value={amount}
                  onChange={e => {
                    updateState({ amount: e.target.value })
                  }}
                />
              </div>
            </Col>
            <Col md={6} className="">
              <div className="mb-3 col-md-12">
                <Label className="form-label"> Loan Duration</Label>
                <Input
                  name="name"
                  type="number"
                  placeholder=""
                  className=" border-0 bg-light rounded-0 p-3"
                  value={duration}
                  onChange={e => {
                    updateState({ duration: e.target.value })
                  }}
                />
              </div>
            </Col>
          </Row>
          <button
            className="btn btn-success float-end"
            onClick={() => {
              __handlePostRequestData()
            }}
          >
            Request
          </button>
        </ModalBody>
      </Modal>
    )
  }
}

export default withTranslation()(LoanRequest)
